<template>
  <div>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-if="!isLoading">
      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Event"
            label-for="event-id"
          >
          <div v-if="events === null" class="text-center">
            <b-spinner label="Loading..."></b-spinner>
          </div>
 
          <v-select
            v-model="currentObject.event_id"
            :options="events"
            :reduce="val => val.value"
            :clearable="true"
            input-id="event-id"
            placeholder="Pick an event"
            @input="fetchEventBooths"
            :disabled="isEdit"
            v-if="events !== null"
          />
          </b-form-group>

          <b-form-group
            label="Event Booth"
            label-for="event-booth-id"
          >

          <div v-if="eventBooths === null" class="text-center">
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <v-select
            v-model="currentObject.event_booth_id"
            :options="eventBooths"
            :reduce="val => val.value"
            :clearable="true"
            input-id="event-id"
            placeholder="Pick an event booth (if any)"
            :disabled="isEdit"
            v-if="eventBooths !== null"
          />
          </b-form-group>
        </b-col>
      </b-row>

        <div style="clear: both" class="mt-2"></div>

        <div class="float-left">
          <h3>Questions</h3>
        </div>

        <div class="float-left">
          <b-button
            variant="primary"
            @click="addQuestion()"
            size="sm"
            class="ml-1"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span>Add</span>
          </b-button>
        </div>

        <div style="clear: both" class="mb-1"></div>

        <b-row v-if="(currentObject.questions.length == 0 || currentObject.questions === null) & !isLoading">
          <b-col cols="12">
            <div class="text-center">
              <div class="demo-spacing-0">
                <b-alert
                  variant="warning"
                  show
                >
                  <div class="alert-body">
                    The question's still empty, try adding some!
                  </div>
                </b-alert>
              </div>
            </div> 
          </b-col>
        </b-row>

        <div v-for="(question, index) in currentObject.questions" :key="index" class="mb-2">
          <b-row class="mb-1">
            <b-col cols="9">
              <h4 class="mb-1">#{{ (index + 1) }}</h4>
              <b-form-group
                label="Question Text"
                label-for="question-name"
              >

              <b-overlay :show="question.isLoading" rounded="sm">
                <vue-editor 
                  v-model="question.name"
                  useCustomImageHandler
                  @focus="currentQuestionIndex = index"
                  @image-added="handleImageAdded"
                  :id="`question-${index}`"
                  :aria-hidden="question.isLoading ? 'true' : null"
                />              
              </b-overlay>

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Answer Types"
                label-for="question-type"
              >
                <div class="float-left mr-1" style="min-width: 120px;">
                  <v-select
                    v-model="question.type"
                    :options="types"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="question-type"
                  />
                </div>

                <div class="float-left">
                  <b-button variant="warning" @click="viewOptions(index)" :disabled="question.type != 'select'">
                    <feather-icon icon="ListIcon"/> Choices
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="Answer Placeholder"
                label-for="question-placeholder"
              >
                <b-form-input
                  id="question-placeholder"
                  v-model="question.placeholder"
                  placeholder="Answer placeholder"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <div class="float-left">
                <b-form-checkbox
                  v-model="question.is_required"
                  v-bind:value="true"
                  v-bind:unchecked-value="false"
                  class="mt-2"
                  style="top: 8px; position: relative;"
                >
                  Required
                </b-form-checkbox>
              </div>

              <div class="float-left ml-2">
                <b-button class="mt-2" variant="danger" @click="removeQuestion(index)">
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </div>

            </b-col>

          </b-row>
        </div>
      </b-form>

      <!-- Action Buttons -->
      <div class="mt-2">
        <div style="float: left">
          <b-button
            variant="primary"
            @click="save()"
            :disabled="!isValid() || isLoading"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>{{ (isLoading) ? "Loading.." : "Save" }}</span>
          </b-button>
        </div>

        <div style="float: right">
          <b-button size="md" variant="outline-primary" @click="back()">
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            /><span>Back</span>
          </b-button>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showOptionsModal"
    >
      <template #modal-header="{}">
        <h5>Choice Options</h5>
      </template>

      <b-form>
          <b-button @click="addOption()" variant="primary" class="mb-2" size="sm">
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span>Add new choice</span>
          </b-button>
        <div>
          <b-row v-for="(option, index) in currentQuestionOptions.options" :key="index" class="mb-1">
            <b-col cols="6">
              <b-form-input
                name="text"
                v-model="currentQuestionOptions.options[index]"
                placeholder="Choice value"
              />
            </b-col>
            <b-col cols="2">
              <b-button variant="danger" @click="removeOption(index)">
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-primary" @click="saveOptions(currentQuestionOptions.index)" :disabled="currentQuestionOptions.options.length < 2">
          Save
        </b-button>
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
      </template>


    </b-modal>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BInputGroup, BInputGroupAppend, BAlert, BModal, BSpinner, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueEditor } from "vue2-editor"
import moment from 'moment'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BModal,
    VueEditor,
    BSpinner,
    moment,
    BOverlay
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    eventBoothId: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      currentQuestionIndex: 0,
      isEdit: false,
      isLoadingUpload: false,

      events: [],
      eventBooths: [],

      isLoading: false,
      showOptionsModal: false,
      currentQuestionOptions: {
        name: '',
        options: [],
        index: -1
      },
      currentObject: {
        id: null,
        event_id: null,
        event_booth_id: null,
        questions: []
      },
      types: [
        {
          label: "Text",
          value: "text"
        },
        {
          label: "Choices",
          value: "select"
        }
      ]
    }
  },
  methods: {
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY')
    },
    fetchEvents() {
      this.isLoading = true

      this.$http.get('/admin/v1/events_upcoming')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
            
            if(currentData !== null) {
              this.events = []

              currentData.forEach((item) => {
                let name = (item.name.length > 15) ? item.name.substr(0, 14) + "...." : item.name

                this.events.push({
                  label: name + " (" + this.formatDateReverse(item.date_start) + " - " + this.formatDateReverse(item.date_end) + ")",
                  value: item.id
                })
              })
            }
          }

          this.isLoading = false
     })
    },

    fetchEventBooths() {
      if(this.currentObject.event_id !== null) {
        this.isLoading = true

        this.$http.get('/admin/v1/events/' + this.currentObject.event_id + '/booths')
          .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const currentData = res.data.data
              
              if(currentData !== null) {
                this.eventBooths = []

                currentData.forEach((item) => {
                  this.eventBooths.push({
                    label: item.name,
                    value: item.id
                  })
                })
              }
            }

            this.isLoading = false
        })
      }
    },

    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // this.isLoading = true
      this.currentObject.questions[this.currentQuestionIndex].isLoading = true

      const requestData = new FormData()

      requestData.append('image', file);

      this.$http.post('/admin/v1/upload_image', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const data = res.data
              const currentData = data.data

              const url = currentData.url

              this.currentObject.questions[this.currentQuestionIndex].isLoading = false

              Editor.insertEmbed(cursorLocation, "image", url)
              
              resetUploader()

            }

            // this.isLoading = false
            this.currentObject.questions[this.currentQuestionIndex].isLoading = false
        })

    },
    back() {
        this.$router.push('/master/questions')
    },
    saveOptions(questionIndex) {
      this.currentObject.questions[questionIndex].attributes.options = []

      this.currentQuestionOptions.options.forEach((item) => {
        this.currentObject.questions[questionIndex].attributes.options.push(item)
      })

      this.showOptionsModal = false
    },
    viewOptions(index) {
      this.showOptionsModal = true

      this.currentQuestionOptions.index = index
      this.currentQuestionOptions.name = this.currentObject.questions[index].name
      this.currentQuestionOptions.options = []

      if(this.currentObject.questions[index].attributes.options !== null) {
        this.currentObject.questions[index].attributes.options.forEach((value) => {
          this.currentQuestionOptions.options.push(value)
        })

        if(this.currentQuestionOptions.options.length == 0) {
          this.currentQuestionOptions.options = [""]
        }
      }
      else {
        this.currentQuestionOptions.options = [""]
      }
    },
    removeQuestion(index) {
      this.currentObject.questions.splice(index, 1)
    },
    removeOption(optionIndex) {
      this.currentQuestionOptions.options.splice(optionIndex, 1)
    },
    addOption() {
      if(this.currentQuestionOptions.options === null) {
        this.currentQuestionOptions.options = []  
      }

      this.currentQuestionOptions.options.push("")
    },
    addQuestion() {
      this.currentObject.questions.push({
          name: null,
          type: 'text',
          placeholder: null,
          attributes: {
            options: null,
          },
          is_required: true,
          id: -1, 
          isLoading: false
        })
    },
    save() {
      this.isLoading = true

      const requestData = {
        event_id: this.currentObject.event_id,
        event_booth_id: this.currentObject.event_booth_id,
        questions: this.currentObject.questions
      }

      if(this.isEdit) {
        this.$http.put('/admin/v1/feedback_questions', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

            }

            this.isLoading = false
        })
      }
      else {
        this.$http.post('/admin/v1/feedback_questions', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger', 
                  text: message,
                },
              })
            }
            else {
              this.$router.replace("/master/questions")
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: message,
                    },
                  })
                })
            }

            this.isLoading = false
        })
      }
    },
    fetchData() {
      this.isLoading = true

      this.fetchEventBooths()
      this.fetchEvents()

      this.$http.post('/admin/v1/feedback_questions_single', {
        event_id: this.currentObject.event_id,
        event_booth_id: (this.currentObject.event_booth_id > 0) ? this.currentObject.event_booth_id : null
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.currentObject.id = currentData.id
            this.currentObject.event_id = currentData.event_id
            this.currentObject.event_booth_id = currentData.event_booth_id

            currentData.questions.forEach((item) => {
              item.isLoading = false
              
              this.currentObject.questions.push(item)
            })
          }

          this.isLoading = false
       })
    },
    isValid() {
      let questionValid = true

      this.currentObject.questions.forEach((currentQuestion) => {
        if(currentQuestion.name === null || currentQuestion.name == "" || currentQuestion.type === null || currentQuestion.type == "") {
          questionValid = false
        }
      } )

      return this.currentObject.event_id != "" && this.currentObject.questions.length > 0 && questionValid
    }
  },
  created() {
    if(this.isEdit) {
      this.currentObject.event_id = parseInt(this.eventId)
      this.currentObject.event_booth_id = parseInt(this.eventBoothId)

      this.isEdit = true

      this.fetchData()
    }
    else {
      this.currentObject.event_id = null
      this.currentObject.event_booth_id = null

      this.isEdit = false

      this.fetchEvents()
      this.fetchEventBooths()

      this.addQuestion()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
